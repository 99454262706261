import React from 'react';
import { Property, Room, RoomType } from 'models/property';
import {
	CreateListingState,
	CreateListingStep,
	CreateListingSubStep,
	StepInfoContent,
	StepStatus,
} from './types';
import theme from 'config/theme';
import numberFormatService from 'services/numberFormatService';
import { FurnishedType } from 'models/listing';
import { LeaseType, PropertyType } from 'models/digs';
import unsupportedFunctionReplacements from 'utils/unsupportedFunctionReplacements';

const { colors } = theme;

interface ValidationResult {
	valid: boolean;
	error?: string;
}

export const steps = [
	CreateListingStep.YourListing,
	CreateListingStep.Features,
	CreateListingStep.Occupants,
	CreateListingStep.Location,
	CreateListingStep.Pictures,
	CreateListingStep.NameListing,
	CreateListingStep.Pricing,
];

const validateStep = (step: CreateListingStep, state: CreateListingState): ValidationResult => {
	if (step === CreateListingStep.HowTo) {
		if (!state.termsAccepted) {
			return {
				valid: false,
				error: 'Terms and conditions not accepted',
			};
		}
		return { valid: true };
	}

	if (step === CreateListingStep.Features) {
		return validateStep(CreateListingStep.YourListing, state);
	}

	if (step === CreateListingStep.NameListing) {
		if (!state.title) {
			return {
				valid: false,
				error: 'Please enter a title for your listing',
			};
		}
		if (!state.description) {
			return {
				valid: false,
				error: 'Please enter a description for your listing',
			};
		}
		if (state.description.length < 50) {
			return {
				valid: false,
				error: 'Please enter a description at least 50 characters long',
			};
		}
		return { valid: true };
	}

	if (step === CreateListingStep.Pricing) {
		if (state.leaseType === LeaseType.RoomByRoom) {
			if (!state.rooms.length) {
				return {
					valid: false,
					error: 'Please select a room',
				};
			}
			if (state.rooms.some(room => !room.price)) {
				return {
					valid: false,
					error: 'Please enter a price for all rooms',
				};
			}
		}
		if (state.leaseType === LeaseType.EntirePlace && !state.price) {
			return {
				valid: false,
				error: 'Please enter a price for your listing',
			};
		}
		if (!state.leaseType) {
			return {
				valid: false,
				error: 'lease type needs to be selected',
			};
		}
		return { valid: true };
	}

	if (step === CreateListingStep.Preview) {
		return validateStep(CreateListingStep.Pricing, state);
	}

	const subSteps = getSubSteps(step) as CreateListingSubStep[];
	if (subSteps && subSteps.length) {
		for (let subStep of subSteps) {
			const validationResult = validateSubStep(subStep, state);
			if (!validationResult.valid) {
				return validationResult;
			}
		}
		return { valid: true };
	}

	return {
		valid: false,
		error: 'Step validation not implemented',
	};
};

const validateSubStep = (
	subStep: CreateListingSubStep,
	state: CreateListingState,
): ValidationResult => {
	if (subStep === CreateListingSubStep.YourListingPropertyType) {
		if (!state.propertyType) {
			return {
				valid: false,
				error: 'Property type not selected',
			};
		}
		return { valid: true };
	}

	if (subStep === CreateListingSubStep.YourListingAvailability) {
		if (state.propertyType !== PropertyType.StudentResidence) {
			if (state.sharedRoomCount < 0 || state.singleRoomCount < 0 || state.bedroomCount < 0) {
				return {
					valid: false,
					error: 'Room count cannot be less than 0',
				};
			}
			if (state.bedroomCount < 1) {
				return {
					valid: false,
					error: 'Listing must have at least 1 room',
				};
			}
			if (state.sharedRoomCount + state.singleRoomCount < 1) {
				return {
					valid: false,
					error: 'Listing must have at least 1  single or shared room',
				};
			}
			if (state.bedroomCount < state.sharedRoomCount + state.singleRoomCount) {
				return {
					valid: false,
					error: `Total bedroom count must be more than the sum of single & shared rooms`,
				};
			}
			if (!state.leaseType) {
				return {
					valid: false,
					error: 'Lease type not selected',
				};
			}
		}
		if (state.propertyType === PropertyType.StudentResidence) {
			if (state.roomTypeCount < 0 || state.bedroomCount < 0) {
				return {
					valid: false,
					error: 'Room count cannot be less than 0',
				};
			}
			if (state.roomTypeCount < 1 && state.bedroomCount < 1) {
				return {
					valid: false,
					error: 'Listing must have at least 1 room',
				};
			}
			if (state.bedroomCount < state.roomTypeCount) {
				return {
					valid: false,
					error: 'Total rooms can not be less than the number of room types',
				};
			}
			if (!state.leaseType) {
				return {
					valid: false,
					error: 'Lease type not selected',
				};
			}
		}
		return { valid: true };
	}

	if (subStep === CreateListingSubStep.YourListingRooms) {
		if (!state.rooms.length) {
			return {
				valid: false,
				error: 'At least one room is required',
			};
		}
		if (state.rooms.some(room => !validateRoom(room, state.leaseType, state.propertyType).valid)) {
			return {
				valid: false,
				error: 'Please complete all rooms',
			};
		}
		return { valid: true };
	}

	if (subStep === CreateListingSubStep.YourListingDetails) {
		if (!state.furnished) {
			return {
				valid: false,
				error: 'Please specify furnished status of the property',
			};
		}
		if (state.leaseType === LeaseType.EntirePlace && !state.availabilityDate) {
			return {
				valid: false,
				error: 'Please choose an availability date',
			};
		}
		if (!state.leasePeriodLength) {
			return {
				valid: false,
				error: 'Please choose a preferred lease period',
			};
		}
		if (state.leasePeriodLength < 2) {
			return {
				valid: false,
				error: 'Lease period must be a minimum of 2 months',
			};
		}
		return { valid: true };
	}

	if (subStep === CreateListingSubStep.OccupantsCapacity) {
		if (!state.totalOccupants) {
			return {
				valid: false,
				error: 'Please enter number of total occupants allowed',
			};
		}
		if (state.currentOccupants && !state.currentTenantGender) {
			return {
				valid: false,
				error: 'Please specify the current tenant gender',
			};
		}
		if (state.currentOccupants + state.availablePlaces > state.totalOccupants) {
			return {
				valid: false,
				error:
					'Current occupants + available places can not be larger than the number of occupants at full capacity',
			};
		}
		if (!state.preferredNewTenantGender) {
			return {
				valid: false,
				error: 'Please specify the preferred tenant gender',
			};
		}
		if (!state.preferredTenantTypes) {
			return {
				valid: false,
				error: 'Please specify tenant preferences',
			};
		}
		return { valid: true };
	}

	if (subStep === CreateListingSubStep.OccupantsRules) {
		return { valid: true };
	}

	if (subStep === CreateListingSubStep.LocationAtmosphere) {
		return { valid: true };
	}

	if (subStep === CreateListingSubStep.LocationAddress) {
		if (!state.address || !state.address.formatted_address) {
			return {
				valid: false,
				error: 'Please specify the address of your property',
			};
		}
		return { valid: true };
	}

	if (subStep === CreateListingSubStep.PicturesUpload) {
		if (state.photos.length < 5) {
			return {
				valid: false,
				error: 'Please upload at least 5 photos',
			};
		}
		return { valid: true };
	}

	if (subStep === CreateListingSubStep.PicturesVideo) {
		return validateSubStep(CreateListingSubStep.PicturesUpload, state);
	}

	return {
		valid: false,
		error: 'Step validation not implemented',
	};
};

const getStepStatuses = (
	steps: CreateListingStep[],
	state: CreateListingState,
): { [step: number]: StepStatus } => {
	return steps.reduce(
		(statuses, step) => ({
			...statuses,
			[step]: validateStep(step, state).valid ? StepStatus.Complete : StepStatus.Incomplete,
		}),
		{},
	);
};

const getSubStepStatuses = (
	steps: CreateListingStep[],
	state: CreateListingState,
): { [step: number]: StepStatus } => {
	return unsupportedFunctionReplacements
		.flatten(steps.map(step => getSubSteps(step)))
		.filter(Boolean)
		.reduce(
			(statuses: any, step: CreateListingSubStep) => ({
				...statuses,
				[step as CreateListingSubStep]: validateSubStep(step as CreateListingSubStep, state).valid
					? StepStatus.Complete
					: StepStatus.Incomplete,
			}),
			{},
		);
};

const getStepInfoContent = (
	step: CreateListingStep,
	subStep: CreateListingSubStep,
	state: CreateListingState,
	numberOfConnections: number,
): StepInfoContent | undefined => {
	if (step === CreateListingStep.HowTo) {
		return {
			title: 'The process',
			items: [
				{
					header: 'Introduction',
					body:
						'Welcome to the ‘create a listing’ process. Here you will add your property step by step  onto DigsConnect. Please take the time to be as detailed as possible, and make sure to include and exclude what your listing involves. This allows tenants to get a better idea of what to expect should they want to move in, and pushes up your listing score to reach tenants faster!',
				},
			],
		};
	}
	if (step === CreateListingStep.YourListing) {
		if (subStep === CreateListingSubStep.YourListingPropertyType) {
			return {
				title: 'Property types',
				items: [
					{
						header: 'House',
						body:
							'A stand alone house, usually consists of a ground floor, and one or more upper stories.',
					},
					{
						header: 'Apartment',
						body: 'A suite of rooms forming one residence; a flat.',
					},
					{
						header: 'Flatlet',
						body: 'A secondary residence on a property with one or more other residences.',
					},
					{
						header: 'Student Residence',
						body:
							'A block specifically catered to students, usually involving multiple similar room types.',
					},
				],
			};
		}
		if (subStep === CreateListingSubStep.YourListingAvailability) {
			if (state.propertyType === PropertyType.StudentResidence) {
				return {
					title: 'Availability',
					items: [
						{
							header: 'Room types',
							body:
								'On a property, multiple rooms that are the same size and price, are one room type. This usually applies to larger properties that have a selection of identical rooms available. Properties with multiple rooms that come in varying sizes and costs will require an individual room type for each specific room.',
						},
					],
				};
			}
			return {
				title: 'Availability',
				items: [
					{
						header: 'Single rooms',
						body:
							'These are rooms dedicated to one tenant. The tenant will have the room all to themselves. They may share communal spaces with other tenants.',
					},
					{
						header: 'Shared rooms',
						body:
							'These rooms are shared by 2 or more tenants. They typically contain 2 separate beds.',
					},
					{
						header: 'Total bedrooms',
						body:
							'The number of bedrooms the property has in it’s entirety. Including currently occupied rooms.',
					},
					{
						header: 'Leasing options',
						body:
							'Are you leasing out all available rooms as a single entire lease, for a single price, or each room as an individual lease to each tenant?',
					},
				],
			};
		}
		if (subStep === CreateListingSubStep.YourListingRooms) {
			return {
				title: 'Bedrooms',
				items: [
					{
						header: '',
						body: 'Tell us about every room on the property.',
					},
					{
						header: '',
						body:
							'This gives potential tenants a clearer idea of the property, so if friends want to move in together, they’ll know more about all the rooms.',
					},
				],
			};
		}
		if (subStep === CreateListingSubStep.YourListingDetails) {
			return {
				title: 'Tell us a bit about the property',
				items: [
					{
						header: 'Property details',
						body:
							'This covers how many bedrooms in total are on the property, how many bathroom, or how many places are still available for tenants. It also covers furnishings included in the property.',
					},
					{
						header: 'Rental terms',
						body:
							'This covers lease periods and move in dates, and your flexibility around these dates.',
					},
				],
			};
		}
	}
	if (step === CreateListingStep.Features) {
		return {
			title: 'Features',
			items: [
				{
					header: 'Pick none, pick one or pick them all!',
					body:
						'Dont worry if you don’t have any of these featuers. This is an exhaustive list that can apply to large-scale residence-type properties as well as two bedroom flats.',
				},
				{
					header: '',
					body: 'As long as it accurately reflects what’s available at your property.',
				},
			],
		};
	}
	if (step === CreateListingStep.Occupants) {
		if (subStep === CreateListingSubStep.OccupantsCapacity) {
			return {
				title: 'Occupants',
				items: [
					{
						header: 'Max occupancy at full capacity',
						body:
							'Include the total number of people that can be accommodated on your property, at full capacity.',
					},
					{
						header: '',
						body:
							'The total number of tenants that can occupy the property gives the potential tenant viewing your listing, an idea of the property size and layout.',
					},
					{
						header: 'Current number of occupants',
						body:
							'Include the total number of people currently living on the premises. Some rooms might be already filled.',
					},
					{
						header: '',
						body:
							'Let tenants know how many people will be sharing the space. This helps them find the best suited property for their needs.',
					},
					{
						header: 'Suitable tenants',
						body:
							'What type of tenant are you looking for. Does male or female matter? You can pick as many options as are applicable.',
					},
				],
			};
		}
		if (subStep === CreateListingSubStep.OccupantsRules) {
			return {
				title: 'Property rules',
				items: [
					{
						header: 'Bend them, don’t break them.',
						body: 'Let tenants know what works for you and what’s not allowed.',
					},
				],
			};
		}
	}
	if (step === CreateListingStep.Location) {
		if (subStep === CreateListingSubStep.LocationAtmosphere) {
			return {
				title: 'What’s it like living on the property',
				items: [
					{
						header: 'Atmosphere',
						body:
							"Tenants will be able to search for properties that fit there needs. Telling them what this property's atmosphere is like, helps them look for the perfect place.",
					},
				],
			};
		}
		if (subStep === CreateListingSubStep.LocationAddress) {
			return {
				title: 'Sharing your address',
				items: [
					{
						header: 'Are you concerned about sharing your address?',
						body:
							'We use the address you provide to calculate the distance from your property to the colleges and university campuses in the surrounding area, using Google Maps.',
					},
					{
						header: '',
						body: 'We also use your address suburb to plot your property on our map search page.',
					},
					{
						header: '',
						body: 'Your exact address is not shared publicly.',
					},
				],
			};
		}
	}
	if (step === CreateListingStep.Pictures) {
		if (subStep === CreateListingSubStep.PicturesUpload) {
			return {
				title: 'Tips for pics',
				items: [
					{
						header: 'It’s the first thing potential tenants see!',
						body:
							'DigsConnect lists property search results according to our own ranking algorithm. For your property to have a good search ranking score, we’d recommend that you have at least 5 images showcasing the property.',
					},
					{
						header: '',
						body:
							'The better the quality of your photo, the higher your score! It’s definately worth putting in some effort into getting great photographs.',
					},
				],
			};
		}
		if (subStep === CreateListingSubStep.PicturesVideo) {
			return {
				title: 'Video tips',
				items: [
					{
						header: 'Video',
						body:
							'A video further allows potential tenants to see what they are applies to. It can also serve as a previewing, giving tenants more confidence in moving in quickly.',
					},
				],
			};
		}
	}
	if (step === CreateListingStep.NameListing) {
		return {
			title: 'Show off the place',
			items: [
				{
					header: 'Title tips',
					body:
						'An eye catching title should describe your property in 1 short sentence, and allow the potential tenants to know whats on offer at a glance.',
				},
				{
					header: 'Description tips',
					body:
						'This is the point to really tell potential tenants about how incredible your property is! Share what’s on offer, mention nearby popular locations and brag about things tenants may love.',
				},
			],
		};
	}
	if (step === CreateListingStep.Pricing) {
		return {
			title: 'Pricing',
			items: [
				{
					header: 'Rental amounts',
					body:
						'Rental amounts are an important factor for both landlords and tenants. It should be affordable for tenants, but worthwhile for you as a landlord. You can see what similar listings in the area you have chosen go for highlighted on this page.',
				},
				{
					header: 'Additional fees',
					body:
						'Are there any additional admin or signup fees included? What about deposits? Let the tenants know.',
				},
			],
		};
	}
	if (step === CreateListingStep.Preview) {
		return {
			title: 'Profile verification',
			items: [
				{
					header: 'What to do next?',
					body: '',
					numberedList: [
						'Once you post your listing, wait for our team to reach out to you after they have moderated your listing and profile.',
						'Once moderated, your listing will go live on DigsConnect!',
						'Potential tenants will make booking enquiries for your listing and message you.',
						'You’ll receive emails and SMSs about these enquiries.',
						'You’ll be able to see these bookings enquiry messages in your Inbox.',
						'The support team will be in contact with you to guide you through the process, all the way to successful placements!',
					],
				},
				{
					header: 'We’re so excited to start this process with you!',
					body: '',
				},
			],
		};
	}
};

const getSubSteps = (step: CreateListingStep): CreateListingSubStep[] | undefined => {
	switch (step) {
		case CreateListingStep.YourListing: {
			return [
				CreateListingSubStep.YourListingDetails,
				CreateListingSubStep.YourListingRooms,
				CreateListingSubStep.YourListingAvailability,
			];
		}
		case CreateListingStep.Occupants: {
			return [CreateListingSubStep.OccupantsCapacity, CreateListingSubStep.OccupantsRules];
		}
		case CreateListingStep.Location: {
			return [CreateListingSubStep.LocationAtmosphere, CreateListingSubStep.LocationAddress];
		}
		case CreateListingStep.Pictures: {
			return [CreateListingSubStep.PicturesUpload, CreateListingSubStep.PicturesVideo];
		}
		default:
			return;
	}
};

const validateRoom = (
	room: Room,
	leaseType: LeaseType,
	propertyType: PropertyType = PropertyType.Unknown,
	validatePhotos: boolean = true,
): ValidationResult => {
	if (!room.title) {
		return {
			valid: false,
			error: 'Please enter a name for this room',
		};
	}
	if (room.title.length > 340) {
		return {
			valid: false,
			error: 'Room title can not exceed 340 characters',
		};
	}
	if (propertyType === PropertyType.StudentResidence && !room.total_available) {
		return {
			valid: false,
			error: 'Select total number of these rooms',
		};
	}
	if (leaseType === LeaseType.RoomByRoom && !room.availability_date) {
		return {
			valid: false,
			error: 'Please select an availability date for this room',
		};
	}
	if (room.bedroom_type === RoomType.Shared && !room.guests) {
		return {
			valid: false,
			error: 'Please enter the number of occupants allowed in this room',
		};
	}
	if (!room.furnished) {
		return {
			valid: false,
			error: 'Please select furnished status',
		};
	}
	if (!room.bathroom_type) {
		return {
			valid: false,
			error: 'Please select bathroom type',
		};
	}
	if (validatePhotos && !room.photos.length) {
		return {
			valid: false,
			error: 'Please upload at least one photo',
		};
	}

	return { valid: true };
};

const propertyToState = (property: Property): CreateListingState => {
	return {
		termsAccepted: Boolean(property.uuid),
		propertyType: property.sub_type as PropertyType,
		leaseType: property.lease_type as LeaseType,
		singleRoomCount: property.available_private_bedrooms || 0,
		roomTypeCount: property.available_room_types || 0,
		sharedRoomCount: property.available_shared_bedrooms || 0,
		bedroomCount: property.total_bedrooms,
		sharedBathrooms: property.total_shared_bathrooms,
		furnished: property.furnished as FurnishedType,
		availabilityDate: property.availability_date,
		flexibleMoveInDate: property.move_in_date_flexible,
		leasePeriodLength: property.lease_term,
		flexibleLeasePeriod: property.lease_term_flexible,
		rooms: property.rooms,
		tenantsPerSharedBathroom: property.tenants_per_shared_bathroom,
		m2: property.property_size,
		currentOccupants: property.current_occupants,
		availablePlaces: property.total_places_available_value,
		totalOccupants: property.total_occupants,
		currentTenantGender: property.current_genders,
		preferredNewTenantGender: property.preferred_genders,
		preferredTenantTypes: property.tenant_preferences,
		address: property.location_data || property.location,
		photos: property.photos,
		videoLink: property.videos.length ? property.videos[property.videos.length - 1].url : '',
		title: property.title,
		description: property.description,
		rentalPeriod: property.billing_cycle,
		price: property.price,
	};
};

const getProgressPercentage = (property: Property): number => {
	const state = propertyToState(property);
	const firstInvalidStep =
		steps.find(step => !validateStep(step, state).valid) || CreateListingStep.Preview;
	const current = steps.indexOf(firstInvalidStep) + 1;
	return Math.round((current / steps.length) * 100);
};

export default {
	validateStep,
	validateSubStep,
	validateRoom,
	getStepStatuses,
	getSubStepStatuses,
	getStepInfoContent,
	getSubSteps,
	getProgressPercentage,
	propertyToState,
};
